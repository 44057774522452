export default [
  {
    path: '/chapters',
    name: 'apps-chapters-list',
    component: () => import('@/views/content/chapters/Chapters.vue'),
    meta: {
      pageTitle: 'Chapters',
      breadcrumb: [
        {
          text: 'Chapters',
          active: true
        },
      ],
    },
  },
  {
    path: '/chapter/:chapter_id/sections',
    name: 'apps-sections-list',
    component: () => import('@/views/content/sections/Sections.vue'),
    meta: {
      pageTitle: 'Sections',
      breadcrumb: [
        {
          text: 'Chapters',
          to: {name: 'apps-chapters-list'}
        },
        {
          text: 'Sections',
          active: true
        }
      ],
    },
  },
  {
    path: '/chapter/:chapter_id/section/:section_id/lessons',
    name: 'apps-lessons-list',
    component: () => import('@/views/content/lessons/Lessons.vue'),
    meta: {
      pageTitle: 'Lessons',
      breadcrumb: [
        {
          text: 'Chapters',
          to: {name: 'apps-chapters-list'}
        },
        {
          text: 'Sections',
        },
        {
          text: 'Lessons',
          active: true
        }
      ],
    },
    beforeEnter(to, from, next) {
      to.meta.breadcrumb[1].to = {name: 'apps-sections-list', params: {id: from.params.chapter_id}}
      next()
    },
  },
  {
    path: '/chapter/:chapter_id/section/:section_id/lesson/:lesson_id/exercises',
    name: 'apps-exercises-list',
    component: () => import('@/views/content/exercises/Exercises.vue'),
    meta: {
      pageTitle: 'Exercises',
      breadcrumb: [
        {
          text: 'Chapters',
          to: {name: 'apps-chapters-list'}
        },
        {
          text: 'Sections',
        },
        {
          text: 'Lessons',
        },
        {
          text: 'Exercises',
          active: true
        }
      ],
    },
    beforeEnter(to, from, next) {
      to.meta.breadcrumb[1].to = {name: 'apps-sections-list', params: {id: from.params.chapter_id}}
      to.meta.breadcrumb[2].to = {name: 'apps-lessons-list', params: {chapter_id: from.params.chapter_id, section_id: from.params.section_id}}
      next()
    },
  },
]