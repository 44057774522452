export default [
  {
    path: '/articles/list',
    name: 'apps-articles-list',
    component: () => import('@/views/articles/Articles.vue'),
    meta: {
      resource: 'content',
      action: 'view',
      pageTitle: 'Articles',
      breadcrumb: [
        {
          text: 'Articles',
          active: true
        },
      ],
    },
  },
  {
    path: '/articles/view/:id',
    name: 'apps-article-view',
    component: () => import('@/views/articles/ArticleView.vue'),
    meta: {
      resource: 'content',
      action: 'view',
      pageTitle: 'Articles',
      breadcrumb: [
        {
          text: 'Articles',
          to: {name: 'apps-articles-list'}
        },
        {
          text: 'Article',
          active: true
        },
      ],
    },
  },
]