import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('userData'))
let abilities = []
if(userData) {
  userData.ability.forEach((ability, index) => {
    abilities.push({"action": ability.split(" ")[1], "subject": ability.split(" ")[0]})
  });
}
const existingAbility = userData ? abilities : null

export default new Ability(existingAbility || initialAbility)
