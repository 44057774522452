export default [
  {
    path: '/logs',
    name: 'apps-logs-list',
    component: () => import('@/views/logs/Logs.vue'),
    meta: {
      pageTitle: 'Logs',
      breadcrumb: [
        {
          text: 'Application Logs',
          active: true
        },
      ],
    },
  },
]