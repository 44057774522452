export default [
  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/apps/forums',
    name: 'apps-forums',
    component: () => import('@/views/apps/chat/Forum.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Students',
      breadcrumb: [
        {
          text: 'Students',
          active: true
        },
      ],
      resource: 'content',
      action: 'view',
    },
  },
  {
    path: '/apps/teachers/list',
    name: 'apps-teachers-list',
    component: () => import('@/views/apps/user/teacher-list/TeacherList.vue'),
    meta: {
      pageTitle: 'Teachers',
      breadcrumb: [
        {
          text: 'Teachers',
          active: true
        },
      ],
    },
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-list/UsersView.vue'),
    meta: {
      pageTitle: 'Students',
      breadcrumb: [
        {
          text: 'Students',
          to: {name: 'apps-users-list'}
        },
        {
          text: 'Student',
          active: true
        }
      ],
      resource: 'content',
      action: 'view',
    },
  },
  {
    path: '/apps/teachers/view/:id',
    name: 'apps-teachers-view',
    component: () => import('@/views/apps/user/teacher-list/TeacherView.vue'),
    meta: {
      pageTitle: 'Teachers',
      breadcrumb: [
        {
          text: 'Teachers',
          to: {name: 'apps-teachers-list'}
        },
        {
          text: 'Teacher',
          active: true
        }
      ],
    },
  },
]
