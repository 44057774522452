export default [
  {
    path: '/apps/videos/list',
    name: 'apps-videos-list',
    component: () => import('@/views/videos/Videos.vue'),
    meta: {
      pageTitle: 'Videos',
      breadcrumb: [
        {
          text: 'Videos',
          active: true
        },
      ],
    },
  },
  {
    path: '/apps/videos/view/:id',
    name: 'apps-video-view',
    component: () => import('@/views/videos/VideoView.vue'),
    meta: {
      pageTitle: 'Video',
      breadcrumb: [
        {
          text: 'Videos',
          to: {name: 'apps-videos-list'}
        },
        {
          text: 'Video',
          active: true,
        },
      ],
    },
  },
]