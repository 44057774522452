export default [
  {
    path: '/teacher/dashboard',
    name: 'teacher-dashboard',
    component: () => import('@/views/teacher/Dashboard.vue'),
    meta: {
      resource: 'content',
      action: 'view',
    },
  },
  {
    path: '/teacher/profile',
    name: 'teacher-profile',
    component: () => import('@/views/teacher/Profile.vue'),
    meta: {
      resource: 'content',
      action: 'view',
    },
  },
  {
    path: '/teacher/content/chapter/:chapter_id/sections',
    name: 'teacher-sections-view',
    component: () => import('@/views/teacher/sections/Sections.vue'),
    meta: {
      resource: 'content',
      action: 'view',
      pageTitle: 'Sections',
      breadcrumb: [
        {
          text: 'Chapters',
          to: { name: 'teacher-dashboard' },
        },
        {
          text: 'Sections',
          active: true,
        },
      ],
    },
  },
  {
    path: '/teacher/content/chapter/:chapter_id/section/:section_id/lessons',
    name: 'teacher-lessons-view',
    component: () => import('@/views/teacher/lessons/Lessons.vue'),
    meta: {
      resource: 'content',
      action: 'view',
      pageTitle: 'Lessons',
      breadcrumb: [
        {
          text: 'Chapters',
          to: { name: 'teacher-dashboard' },
        },
        {
          text: 'Sections',
        },
        {
          text: 'Lessons',
          active: true,
        },
      ],
    },
    beforeEnter(to, from, next) {
      // eslint-disable-next-line no-param-reassign
      to.meta.breadcrumb[1].to = {
        name: 'teacher-sections-view',
        params: { id: from.params.chapter_id },
      }
      next()
    },
  },
  {
    path: '/teacher/content/chapter/:chapter_id/section/:section_id/lesson/:lesson_id/exercises',
    name: 'teacher-exercises-view',
    component: () => import('@/views/teacher/exercises/Exercises.vue'),
    meta: {
      resource: 'content',
      action: 'view',
      pageTitle: 'Exercises',
      breadcrumb: [
        {
          text: 'Chapters',
          to: { name: 'teacher-dashboard' },
        },
        {
          text: 'Sections',
        },
        {
          text: 'Lessons',
        },
        {
          text: 'Exercises',
          active: true,
        },
      ],
    },
    beforeEnter(to, from, next) {
      // eslint-disable-next-line no-param-reassign
      to.meta.breadcrumb[1].to = {
        name: 'teacher-sections-view',
        params: { id: from.params.chapter_id },
      }
      // eslint-disable-next-line no-param-reassign
      to.meta.breadcrumb[2].to = {
        name: 'teacher-lessons-view',
        params: {
          chapter_id: from.params.chapter_id,
          section_id: from.params.section_id,
        },
      }
      next()
    },
  },
]
